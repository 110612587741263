import "./App.css";

function App() {
  // componentDidMount() {
  // }
  return (
    <div className="App">
      <header className="App-header"></header>
    </div>
  );
}

export default App;
