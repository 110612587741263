import "./App.css";

const Footer = () => (
<footer>
	<p>Joia Creative</p>
	<p className="footer-copy">&copy; 2022</p>
</footer>
);

export default Footer;
