import "./App.css";

const Info = () => (
  <>
    <div className="info-container" id="info">
      <div className="quote-wrapper">
        <div className="quote-container">
          <h1>
            “It follows that in order to change an existing imagined order, we
            must first believe in an alternative imagined order.”
          </h1>{" "}
          <p>- Yuval Harari</p>
        </div>
      </div>
      <h1>About</h1>
      <div className="info-copy">
        <h3>Our Mission</h3>
        <p>
          Telling stories that envision a healthier, more sustainable world.
        </p>
        <h3>Our Work</h3>
        <p>
          Joia Creative conceptualizes and produces televised content that both
          celebrates and grapples with the human experience within the natural
          world. How can we live, eat and thrive on a healthy planet? There are
          solutions — and Joia’s purpose is to tell the stories that help bring
          these solutions into focus.
        </p>
        <p>
          We create concepts that explore plant-based eating, sustainability,
          food innovation and eco-travel. We believe in giving voice to
          underrepresented peoples, and in highlighting diverse perspectives and
          overlooked cultural traditions. In all, Joia Creative hopes to
          delight, inspire and educate — to show their audience a future where
          we can live healthier, more vibrant and more sustainable lives.
        </p>
        <h3>Our Team</h3>
        <p>
          Recognizing a gap in the market, creatives Lauren Medsker and Rick
          Mitchell began to write content that explores themes of sustainability
          and healthy living. Through Joia Creative, they now channel decades of
          accumulated knowledge in this realm to create inspired, rich, and
          thought-provoking content.
        </p>
        <p>
          Lauren Medsker is an award-winning cartographer and storyteller who
          utilizes her vast knowledge of geography, culture and urban
          sustainability to inspire and inform viewers. As an accomplished
          visual artist, she also brings a powerful visual aesthetic and an
          artist’s eye to her work. She also holds a certificate in Plant-Based
          Nutrition from the T. Colin Campbell Center for Nutrition Studies at
          eCornell.
        </p>{" "}
        <p>
          Rick Mitchell, a former touring musician, has worked as a writer,
          researcher, software engineer and content creator for over a decade.
          He has written for print and online, and has contributed writing,
          research and design to a number of diverse projects, including a tech
          start-up, Urban Outfitters and a national nonprofit.
        </p>{" "}
        <p>
          They are joined by several team members who collectively have decades
          of experience producing and editing reality-based television.
        </p>
      </div>
    </div>
  </>
);

export default Info;
